<template>
  <div class="sdk-version-table-container">
    <a-table
      class="m_table_cust"
      size="middle"
      :pagination="false"
      :rowKey="(record, index) => index"
      :columns="columns"
      :data-source="dataList"
      tableLayout="fixed"
      :loading="isLoading"
      bordered
    >
      <span slot="os" slot-scope="os">
        {{ +os === 1 ? '安卓' : 'IOS' }}
      </span>
      <div slot="action" slot-scope="record">
        <a-button size="small" class="action-btn" @click="handleUpdatePlatSDK(record)">编辑</a-button>
      </div>
    </a-table>
    <a-pagination
      class="pagination"
      v-if="dataList.length > 0"
      show-size-changer
      :current="query.page"
      :total="total"
      @change="handleChangePage"
      :show-total="(total) => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange"
      :pageSize="query.limit"
    />
    <m-empty v-else></m-empty>
    <addPlatSDKModal
      :positionList="positionList"
      :platList="platList"
      :visible="addSDKVisible"
      :sdkInfo="sdkInfo"
      :isAdd="false"
      @modalCancel="addSDKVisible = false"
      @changeAppInfo="handleAddSDKModal"
    />
  </div>
</template>

<script>
import { getSourceSdkPackagePager } from '@/api/system'
import addPlatSDKModal from '../components/addPlatSDKModal'
import mixGetList from '@/mixins/getList'
export default {
  components: { addPlatSDKModal },
  data () {
    return {
      addSDKVisible: false,
      sdkInfo: {},
      query: {
        page: 1,
        limit: 10
      },
      dataList: [],
      total: 0,
      isLoading: false,
      columns: [
        {
          title: '广告平台',
          dataIndex: 'name',
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          title: '系统类型',
          dataIndex: 'os',
          scopedSlots: {
            customRender: 'os'
          }
        },
        {
          title: `版本号`,
          dataIndex: 'version',
          scopedSlots: {
            customRender: 'version'
          }
        },
        {
          title: `更新时间`,
          dataIndex: 'uploadTime',
          scopedSlots: {
            customRender: 'uploadTime'
          }
        },
        {
          title: `操作`,
          width: '250px',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  mixins: [mixGetList],
  async created () {
    this.getDataList()
    await this.getPlatList()
    this.platList = this.platList.filter((item) => +item.accessType === 0)
    this.getPositionList()
  },
  methods: {
    handleUpdatePlatSDK (record) {
      this.sdkInfo = record
      this.addSDKVisible = true
    },
    handleUpdateSdk (record) {
      this.sdkInfo = record
      this.addSDKVisible = true
    },
    handleAddSDKModal () {
      this.addSDKVisible = false
      this.getDataList()
    },
    handleQuery (query) {
      this.query = {
        page: 1,
        limit: this.query.limit,
        ...query
      }
      this.getDataList()
    },
    async getDataList () {
      this.isLoading = true
      const resp = await getSourceSdkPackagePager(this.query)
      this.dataList = resp.data.items ? resp.data.items : []
      this.total = resp.data.total ? resp.data.total : 0
      this.isLoading = false
    },
    handleChangePage (page) {
      this.query.page = page
      this.getDataList()
    },
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getDataList()
    }
  }
}
</script>
<style lang="less" scoped>
.sdk-version-table-container {
  padding: 10px;
  .pagination {
    padding: 10px;
  }
}
</style>
