<template>
  <div class="plat-version-search-container">
    <a-button icon="plus" type="primary" @click="addSDKVisible = true">新增广告平台版本</a-button>
    <div class="search-wrapper">
      <a-select
        class="search_item"
        :allowClear="true"
        placeholder="请选择广告平台"
        v-model="searchquery.platId"
        @change="handleAutoSearch"
        :filter-option="filterOption"
        showSearch
      >
        <a-select-option v-for="item in platList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
      </a-select>
      <a-select
        class="search_item"
        :allowClear="true"
        placeholder="请选择操作系统"
        v-model="searchquery.os"
        @change="handleSelectOs"
      >
        <a-select-option v-for="item in osList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
      </a-select>
      <a-select
        class="search_item"
        :allowClear="true"
        placeholder="请选择版本"
        v-model="searchquery.version"
        @change="handleAutoSearch"
        :filter-option="filterOption"
        showSearch
      >
        <a-select-option v-for="item in versionList" :key="item.id" :value="item.id">{{
          item.version
        }}</a-select-option>
      </a-select>
    </div>
    <addPlatSDKModal
      :positionList="positionList"
      :platList="platList"
      :visible="addSDKVisible"
      :sdkInfo="{}"
      :isAdd="true"
      @modalCancel="addSDKVisible = false"
      @changeAppInfo="handleAddSDKModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getPublishedSdkList } from '@/api/system'
import mixGetList from '@/mixins/getList'
import addPlatSDKModal from '../components/addPlatSDKModal'
export default {
  components: { addPlatSDKModal },
  data () {
    return {
      osList: [
        { name: '安卓', id: '1' },
        { name: 'IOS', id: '0' }
      ],
      versionList: [],
      searchquery: {
        os: undefined,
        version: undefined,
        platId: undefined
      },
      addSDKVisible: false
    }
  },
  mixins: [mixGetList],
  async created () {
    this.getPublishedSdkList()
    await this.getPlatList()
    this.platList = this.platList.filter((item) => +item.accessType === 0)
    this.getPositionList()
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      isAdmin: (state) => state.user.isAdmin,
      title: (state) => state.autoweb.title
    })
  },
  methods: {
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleAddPlatSDK () {},
    handleAutoSearch () {
      this.$emit('handleAutoSearch', this.searchquery)
    },
    async handleSelectOs (e) {
      await this.getPublishedSdkList()
      this.handleAutoSearch()
    },
    async getPublishedSdkList () {
      const resp = await getPublishedSdkList({
        os: this.searchquery.os
      })
      this.versionList = resp.data || []
      const hasVersion = this.versionList.find((item) => item.id === this.searchquery.version)
      if (!hasVersion) {
        this.searchquery.version = undefined
      }
    },
    handleAddSDKModal () {
      this.addSDKVisible = false
      this.handleAutoSearch()
    }
  }
}
</script>

<style lang="less">
.plat-version-search-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  .search-wrapper {
    .search_item {
      width: 220px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
</style>
