<template>
  <a-modal
    v-model="addSDKVisible"
    :title="(isAdd ? '新增' : '修改') + `广告平台SDK包`"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="700"
  >
    <a-form-model ref="ruleForm" :model="query" v-bind="layout">
      <!-- os -->
      <a-form-model-item
        label="操作系统"
        prop="os"
        :rules="[{ required: true, message: '请选择操作系统', trigger: 'change' }]"
      >
        <a-select :allowClear="true" placeholder="请选择操作系统" v-model="query.os" :disabled="!isAdd">
          <a-select-option :value="1">安卓</a-select-option>
          <a-select-option :value="0">IOS</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- 广告平台 -->
      <a-form-model-item
        label="广告平台"
        prop="platId"
        :rules="[{ required: true, message: '请选择广告平台', trigger: 'change' }]"
      >
        <a-select
          :filter-option="filterOption"
          showSearch
          :allowClear="true"
          placeholder="请选择广告平台"
          v-model="query.platId"
          :disabled="!isAdd"
        >
          <a-select-option v-for="item in platList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- 广告样式 -->
      <a-form-model-item
        label="广告样式"
        prop="sourcePositions"
        :rules="[{ required: true, message: '请选择广告样式', trigger: 'change' }]"
      >
        <a-checkbox-group class="checkbox_group" v-model="query.sourcePositions">
          <a-checkbox class="checkbox_item" v-for="item in positionList" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>

      <!-- SDK版本号 -->
      <a-form-model-item
        label="SDK版本号"
        prop="version"
        :rules="[{ required: true, message: '请输入SDK版本号', trigger: 'blur' }]"
      >
        <a-input :disabled="!isAdd" placeholder="请输入SDK版本号" v-model.trim="query.version" />
      </a-form-model-item>
      <!-- 上传SDK -->
      <a-form-model-item
        label="上传SDK包"
        prop="sdkDownloadUrl"
        :rules="[{ required: true, message: '请上传文件', trigger: 'change' }]"
      >
        <a-upload
          :key="uploadKey"
          name="file"
          :multiple="false"
          :action="uploadUrl"
          :headers="headers"
          @change="handleChange"
          :remove="handleRemoveFile"
          accept=".zip"
          :defaultFileList="fileList"
        >
          <a-button> <a-icon type="upload" /> 点击上传 </a-button>
        </a-upload>
      </a-form-model-item>
      <!-- 上传广平台-->
      <!-- <a-form-model-item
        label="上传广平台"
        prop="adapterDownloadUrl"
        :rules="[{ required: true, message: '请上传文件', trigger: 'change' }]"
      >
        <a-upload
          :key="uploadPlatKey"
          name="file"
          :multiple="false"
          :action="uploadUrl"
          :headers="headers"
          @change="handleChangePlat"
          :remove="handleRemoveFilePlat"
          accept=".zip"
          :defaultFileList="fileListPlat"
        >
          <a-button> <a-icon type="upload" /> 上传适配包 </a-button>
        </a-upload>
      </a-form-model-item> -->
    </a-form-model>
  </a-modal>
</template>

<script>
import { updateSourceSdkPackage, addSourceSdkPackage } from '@/api/system'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {
        os: undefined,
        version: undefined,
        sdkDownloadUrl: undefined,
        // adapterDownloadUrl: undefined,
        sourcePositions: [],
        platId: undefined
      },
      fileList: [],
      // fileListPlat: [],
      uploadKey: '1',
      // uploadPlatKey: '1',
      uploadUrl: process.env.VUE_APP_API_BASE_URL + '/accessCenter/upload'
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    platList: {
      type: Array,
      default: () => []
    },
    positionList: {
      type: Array,
      default: () => []
    },
    sdkInfo: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (this.isAdd) {
          this.query = {
            os: undefined,
            version: undefined,
            sdkDownloadUrl: undefined,
            // adapterDownloadUrl: undefined,
            sourcePositions: [],
            platId: undefined
          }
          this.fileList = []
          // this.fileListPlat = []
        }
        if (val) {
          this.$nextTick(() => {
            this.$refs.ruleForm.clearValidate()
          })
        }
        if (!val || this.isAdd) {
          return
        }
        this.query = JSON.parse(JSON.stringify(this.sdkInfo))
        this.query.sdkDownloadUrl &&
          (this.fileList = [
            {
              uid: '1',
              name: 'SDK.zip',
              status: 'done'
            }
          ])
        // this.query.adapterDownloadUrl &&
        //   (this.fileListPlat = [
        //     {
        //       uid: '2',
        //       name: '平台.zip',
        //       status: 'done'
        //     }
        //   ])
        this.uploadKey = '' + Math.random()
        // this.uploadPlatKey = '' + Math.random()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    headers () {
      return {
        Authorization: this.token
      }
    },
    addSDKVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    ...mapState({
      token: (state) => state.user.token,
      role: (state) => state.user.roles[0],
      isAdmin: (state) => state.user.isAdmin,
      title: (state) => state.autoweb.title
    })
  },
  methods: {
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleChange (info) {
      if (info.file.status === 'uploading') {
        return
      }
      if (info.file.status === 'done') {
        this.uploadKey = '' + Math.random()
        this.query.sdkDownloadUrl = info.file.response.data
        const file = info.fileList.length > 1 ? info.fileList[1] : info.fileList[0]
        this.fileList = [file]
      }
    },
    // handleChangePlat (info) {
    //   if (info.file.status === 'uploading') {
    //     return
    //   }
    //   if (info.file.status === 'done') {
    //     this.uploadPlatKey = '' + Math.random()
    //     this.query.adapterDownloadUrl = info.file.response.data
    //     const file = info.fileList.length > 1 ? info.fileList[1] : info.fileList[0]
    //     this.fileListPlat = [file]
    //   }
    // },
    handleRemoveFile () {
      this.fileList = []
      this.query.sdkDownloadUrl = undefined
    },
    // handleRemoveFilePlat () {
    //   this.fileListPlat = []
    //   this.query.adapterDownloadUrl = undefined
    // },
    handleCancel () {
      this.addSDKVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            const resp = await addSourceSdkPackage(this.query)
            if (resp.code === 200) {
              this.$message.success('添加成功!')
              this.$emit('changeAppInfo', 'add')
            }
          } else {
            const resp = await updateSourceSdkPackage(this.query)
            if (resp.code === 200) {
              this.$message.success('修改成功!')
              this.$emit('changeAppInfo', 'update')
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.checkbox_group {
  .checkbox_item {
    width: 120px;
    margin-left: 0;
  }
}
</style>
