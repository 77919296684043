<template>
  <div class="plat-version-manage-container">
    <platVersionSearch @handleAutoSearch="handleAutoSearch" />
    <platVersionTable ref="table" />
  </div>
</template>

<script>
import platVersionSearch from './platVersionSearch'
import platVersionTable from './platVersionTable'
export default {
  components: { platVersionSearch, platVersionTable },
  methods: {
    handleAutoSearch (query) {
      this.$nextTick(() => {
        this.$refs.table.handleQuery(query)
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
